<template>
  <Buy>
    <template #default>
      <slot />
    </template>
  </Buy>
</template>

<style>
  /* Hide the reCAPTCHA badge, it gets in the way on small screens */
  .grecaptcha-badge {
    display: none;
  }
</style>
